$(window).on('load', function() {
  var win = window.innerWidth ? window.innerWidth : $(window).width();
  if (win > 600) {
    var pagetop = $('#pagetop-pc');

    // Initial button position
    var buttonposition = {bottom: '-80px', right: '20px'}
    pagetop.css(buttonposition);
    $(document.getElementById('main')).scroll(function () {
      if ($(document.getElementById('main')).scrollTop() >= 100) { // Display when scroll reaches 100 or more
        pagetop.stop().animate({bottom: '20px'}, 300);
      } else { // Hide if:
        pagetop.stop().animate({bottom: '-80px'}, 300);
      }
    });

    // Smooth scrolling within a specified box
    var win = window.innerWidth ? window.innerWidth : $(window).width();
    $('a[href^=#]').click(function () {
      if (win > 768 || !$(this).data('box')) {
        var speed = 500;
        var href = $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        var position = target.offset().top;
        $('body,html').animate({scrollTop: position}, speed, 'swing');
      } else {
        var $box = $($(this).data('box'));
        var $target = $($(this).attr('href'));
        var dist = $target.position().top - $box.position().top;
        $box.stop().animate({
          scrollTop: $box.scrollTop() + dist
        });
      }
      return false;
    });
  }
});
